import template from '@admin/angular/templates/order_shared_fields.html';

(() => {
  const app = angular.module('app');

  const MIN_SCHEDULED = moment().subtract(4, 'days').format('YYYY-MM-DD');
  const MAX_SCHEDULED = moment().add(2, 'years').format('YYYY-MM-DD');

  app.component('orderSharedFields', {
    template,
    bindings: {
      order: '<',
      existingOrders: '<',
      account: '<',
      onboardingDate: '<?',
      warehouseReturn: '<',
    },
    controller: [
      'UserService',
      'MOVE_RESCHEDULE_FEE_DESCRIPTION',
      function (UserService, MOVE_RESCHEDULE_FEE_DESCRIPTION) {
        this.moveRescheduleFeeDescription = MOVE_RESCHEDULE_FEE_DESCRIPTION;

        this.MIN_SCHEDULED = MIN_SCHEDULED;
        this.MAX_SCHEDULED = MAX_SCHEDULED;

        const reset = () => {
          this.user = { id: UserService.id, type: 'User' };
          this.customer = { id: this.account.customer.id, type: 'Customer' };
        };

        this.$onInit = reset;
        this.$onChanges = reset;

        this.$doCheck = () => {
          if (this.order.moving) {
            this.order.labor_exception = undefined;
          }
        };

        this.reschedule = () => {
          this.order.rescheduling = this.order.id && this.order.state !== 'pending' && this.schedulable();
        };

        this.schedulable = () =>
          this.order.external && !this.warehouseReturn && (!this.order.address || this.order.address.schedulable);

        this.has_schedule_override_permission = () =>
          ['admin', 'dispatcher', 'manager'].some(_.unary(UserService.hasRole));

        this.updateLaborException = (value) => {
          if (value) {
            this.order.labor_exception = { reason: '' };
          } else {
            this.order.labor_exception = undefined;
          }
        };

        this.orderDisplayName = (order) =>
          `${moment(order.scheduled).format('dddd, MMMM Do, YYYY HH:mm')} - ${order.type} - ${order.subtype} - ${
            order.number
          }`;

        this.onScheduledOverrideReason = (value) => {
          this.order.scheduled_override_reason = value;
        };

        this.onSLAWindowSizeOverride = (value) => {
          this.order.sla_window_size = parseInt(value.slice(0, 1)) * 2; // ::FieldWorkerMinute::DEFAULT_DURATION is 30 minutes, but options are in hour increments
        };

        this.slaWindowSizeText = () => {
          const hours = this.order.sla_window_size / 2; // ::FieldWorkerMinute::DEFAULT_DURATION is 30 minutes, but options are in hour increments
          return `${hours} Hour SLA`;
        };

        this.onOverrideMovers = (value) => {
          this.order.override_movers = value;
        };

        this.onMovers = (value) => {
          this.order.movers = value;
        };

        this.onMoverOverrideReason = (value) => {
          this.order.mover_override_reason = value;
        };

        this.updateExceptionReason = (value) => {
          this.order.labor_exception.reason = value;
        };

        this.showMovingReschuledingWarning = () => moment().isAfter(moment(this.onboardingDate).subtract(2, 'days'));

        this.onSelectPredecessorID = (predecessorID) => {
          this.order.predecessor_id = predecessorID;
        };

        this.onFollowUpJobException = (checked) => {
          if (!checked) {
            this.order.follow_up_job_exception_reason = null;
          }
        };

        this.onFollowUpJobExceptionReason = (value) => {
          this.order.follow_up_job_exception_reason = value;
        };

        this.onSelectRegion = (region) => {
          this.order.region = region;
        };

        this.onSelectSubtype = (subtype) => {
          this.order.subtype = subtype;
        };
      },
    ],
  });
})();
