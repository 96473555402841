/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["order", "dispatch",  "$scope"] }] */
/* eslint no-shadow: ["error", { "allow": ["dragging"] }] */
/* eslint no-underscore-dangle: ["error", { "allow": ["_destroy"] }] */
import { UUID } from '@shared/utils/uuid';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import { Status } from '@admin/components/helpers/error_icon';
import { isNotWarehouseOrder, isWarehouseOrder } from '../../helpers/order';
import { MarkerGenerator } from '../../helpers/styled-marker-maker';

const MODES = {
  VIEWING: 'viewing',
  MODIFYING: 'modifying',
};

const MISSING_ESTIMATED_DURATION_MESSAGE = 'Please estimate this order first!';

angular.module('app').controller('DispatcherController', [
  '$scope',
  '$state',
  '$filter',
  '$http',
  '$location',
  '$q',
  'Dispatch',
  'User',
  'Order',
  'Region',
  'Depot',
  'Warehouse',
  'VehicleType',
  'FieldShiftAssignment',
  'DebounceService',
  'FiltersService',
  'ErrorService',
  'AccountService',
  'GoogleMapStyleConstants',
  'DispatcherConstants',
  'DispatcherPositionService',
  'ngDialog',
  'MapService',
  'MarkerService',
  function (
    $scope,
    $state,
    $filter,
    $http,
    $location,
    $q,
    Dispatch,
    User,
    Order,
    Region,
    Depot,
    Warehouse,
    VehicleType,
    FieldShiftAssignment,
    DebounceService,
    FiltersService,
    ErrorService,
    AccountService,
    GoogleMapStyleConstants,
    DispatcherConstants,
    DispatcherPositionService,
    ngDialog,
    MapService,
    MarkerService,
  ) {
    const percentage = $filter('percentage');
    const sectioned = $filter('sectioned');
    const debounce = DebounceService.initialize();
    let dragging = null;
    let draggingDispatchOffset = null;

    $scope.getCustomerNames = AccountService.getCustomerNames;
    $scope.mode = MODES.VIEWING;
    $scope.mapEnabled = false;
    $scope.colors = GoogleMapStyleConstants.COLORS;
    $scope.map = {
      control: {},
      center: {
        latitude: GoogleMapStyleConstants.DEFAULT_LATITUDE,
        longitude: GoogleMapStyleConstants.DEFAULT_LONGITUDE,
      },
      styles: $scope.color,
      zoom: 11,
    };

    const markerService = new MarkerService($scope.map);

    $scope.regions = Region.query();
    $scope.depots = Depot.query({ status: 'all' });
    $scope.warehouses = Warehouse.query();
    $scope.vehicleTypes = VehicleType.query();
    $scope.users = User.dispatching();
    $scope.filters = $location.search();
    $scope.showAutoDispatch = () =>
      $scope.mode === 'modifying' &&
      $scope.undispatched &&
      $scope.undispatched.orders &&
      $scope.undispatched.orders.length;

    $scope.hasUnsavedDispatches = () =>
      $scope.dispatches.some((dispatch) => dispatch.id === null || dispatch.id === undefined);

    $scope.hasSavedDispatches = () =>
      $scope.dispatches.some((dispatch) => dispatch.id !== null && dispatch.id !== undefined);

    if ($scope.filters.region_id) {
      $scope.filters.region_id = Number($scope.filters.region_id);
    }
    if ($scope.filters.date) {
      $scope.filters.date = new Date(moment($scope.filters.date, 'YYYY-MM-DD'));
    }

    /* Map Functions */
    const routesByDispatch = {};
    const directions = {};

    const unhideRoutes = () =>
      $scope.dispatches.forEach((dispatch) => {
        const map = $scope.map.control.getGMap();
        routesByDispatch[dispatch.mapID].setMap(map);
        routesByDispatch[dispatch.mapID].setDirections(directions[dispatch.mapID]);
      });

    const clearRoutes = () => _.forOwn(routesByDispatch, (dr) => dr.setMap(null));
    const clearRoute = (dispatchId) => routesByDispatch[dispatchId].setMap(null);
    const clearRoutesExcept = (dispatchException) =>
      _.forOwn(routesByDispatch, (dr, currDispatchId) => {
        if (dispatchException.id !== Number(currDispatchId)) clearRoute(currDispatchId);
      });
    const resetMap = () => {
      clearRoutes();
      markerService.clearMarkers();
    };

    function orderClassifier(order) {
      if (order.type === 'pickup' && order.subtype === 'onboarding') {
        return 'Onboarding';
      } else if (order.type === 'pickup' && order.subtype === 'subsequent') {
        return 'Subsequent pickup';
      } else if (order.type === 'return' && order.subtype === 'subsequent') {
        return 'Subsequent return';
      } else if (order.type === 'return' && order.subtype === 'final') {
        return 'Final return';
      }

      return 'Error';
    }

    /** TODO: Currently marker click events are not being cleaned up which would be a problem if
        we decide to have non-idempotent logic inside
     */
    function addMarkerClickEvent({ marker, dispatch, map, window }) {
      marker.addListener('click', () => {
        $scope.focusedRoutes = [dispatch];
        markerService.hideMarkers([dispatch.mapID]);
        clearRoutesExcept(dispatch);
        window.open(map, marker);
      });
      markerService.addMarker(dispatch.mapID, marker);
    }

    function createWindowWithOrder(address, order) {
      const scheduledTime = new Date(order.scheduled);
      const scheduleTime = $filter('datetz')(scheduledTime, order.region.tz, 'h:mm', true).replace(/:00/g, '');

      const endTime = $filter('datetz')(
        scheduledTime.getTime() + order.sla_window_size * DispatcherConstants.DEFAULT_WINDOW_SLOT_DURATION * 1000,
        order.region.tz,
        'h:mm a',
        true,
      ).replace(/:00/g, '');

      return new google.maps.InfoWindow({
        content: `
          <div class="map-info-window">
            <p>${order.id}</p>
            <h4>
              ${order.movers}. ${order.account.customers[0].name} ${scheduleTime} - ${endTime}
            </h4>
            <p>
              ${orderClassifier(order)}, Estimated Duration: ${Math.round(order.estimated_duration / 60)} Minutes
            </p>
            <p>${address}</p>
          </div>
      `,
      });
    }

    function createWindowWithAddress(address) {
      return new google.maps.InfoWindow({ content: address });
    }

    function createWindow(address, order) {
      if (address && order) return createWindowWithOrder(address, order);
      return createWindowWithAddress(address);
    }
    function point(address) {
      return {
        lat: Number(address.latitude),
        lng: Number(address.longitude),
      };
    }

    function plotUndispatchedOrder(order) {
      const orderPoint = point(order.address);
      MapService.ready().then(() => {
        const map = $scope.map.control.getGMap();
        const marker = new google.maps.Marker({
          position: orderPoint,
          map,
        });
        const address = order.address.line1;
        const window = createWindow(address, order);
        marker.addListener('click', () => window.open(map, marker));
        google.maps.event.addListener(map, 'click', () => window.close());
        markerService.addMarker(order.id, marker);
        marker.setMap(map);
      });
    }
    function isFocused(dispatch) {
      return $scope.focusedRoutes.find((focusedDispatch) => focusedDispatch.mapID === dispatch.mapID) !== undefined;
    }

    function anyRoutesFocused() {
      return $scope.focusedRoutes.length !== $scope.dispatches.length;
    }

    function chronologicalComparatorForOrders(a, b) {
      const aOffset = moment.duration(a.dispatch_offset, DispatcherConstants.DISPATCHER_OFFSET_UNITS);
      const bOffset = moment.duration(b.dispatch_offset, DispatcherConstants.DISPATCHER_OFFSET_UNITS);

      const aScheduled = moment(moment.tz(a.scheduled, a.region.tz) + aOffset);
      const bScheduled = moment(moment.tz(b.scheduled, b.region.tz) + bOffset);
      return (
        aScheduled.hour() * 60 * 60 +
          aScheduled.minute() * 60 -
          (bScheduled.hour() * 60 * 60 + bScheduled.minute() * 60) ||
        a.account.customers[0].name.localeCompare(b.account.customers[0].name) ||
        a.id - b.id
      );
    }

    function plot(dispatch, index) {
      if (!isFocused(dispatch)) return;
      const dispatchDepot = $scope.depots.find((depot) => depot.id === dispatch.depot.id);
      const ds = new google.maps.DirectionsService();

      routesByDispatch[dispatch.mapID] =
        routesByDispatch[dispatch.mapID] ||
        new google.maps.DirectionsRenderer({
          polylineOptions: {
            strokeOpacity: 0.8,
            strokeColor: $scope.colors[index],
          },
          suppressMarkers: true,
        });
      const currRoute = routesByDispatch[dispatch.mapID];

      const sorted = angular.copy(dispatch.orders).sort(chronologicalComparatorForOrders);

      const origin = sorted.shift();
      if (!origin) return;

      const destination = sorted.pop() || origin;

      const wayPoints = [];
      const orders = [];
      let destinationPoint = point(destination.address);
      sorted.forEach((order) => {
        wayPoints.push({ location: point(order.address) });
        orders.push(order);
        if (order.moving_operation != null) {
          wayPoints.push({ location: point(order.moving_operation.destination_address) });
          orders.push(order);
        }
      });

      if (origin.moving_operation != null) {
        wayPoints.unshift({ location: point(origin.moving_operation.destination_address) });
        orders.unshift(origin);
      }

      if (destination.moving_operation != null) {
        wayPoints.push({ location: point(destination.address) });
        orders.push(destination);
        destinationPoint = point(destination.moving_operation.destination_address);
      }

      orders.unshift(origin);
      orders.push(destination);
      orders.unshift(null);
      wayPoints.unshift({ location: point(origin.address) });
      if (origin !== destination) {
        wayPoints.push({ location: destinationPoint });
      }
      const request = {
        origin: point(dispatchDepot.address),
        destination: point(dispatchDepot.address),
        waypoints: wayPoints,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      ds.route(request, (response, status) => {
        if (status !== 'OK') {
          // eslint-disable-next-line no-console
          console.error('Google maps api request error', {
            status,
            response,
            dispatch,
            index,
            request,
            orders,
          });
          return;
        }

        const map = $scope.map.control.getGMap();
        currRoute.setMap(map);
        currRoute.setDirections(response);
        const { routes } = response;
        const windows = [];

        // For each route attach custom markers at each stop. Match the custom marker to the route color. Finally
        // Add an info window
        routes.forEach((route) => {
          const { legs } = route;
          legs.forEach((leg, i) => {
            if (i === 0) return;
            const startAddressMarker = MarkerGenerator({
              bgColor: $scope.colors[index],
              fontColor: 'white',
              label: String.fromCharCode(65 + i - 1),
              position: leg.start_location,
              map,
            });

            const startAddressWindow = createWindow(leg.start_address, orders[i]);
            windows.push(startAddressWindow);
            addMarkerClickEvent({
              marker: startAddressMarker,
              window: startAddressWindow,
              map,
              dispatch,
            });

            if (i === legs.length - 1) {
              const endAddressMarker = new google.maps.Marker({
                position: leg.end_location,
                icon: 'https://s3-us-west-2.amazonaws.com/clutterapp/img/truck.png',
                map,
              });

              const endAddressWindow = createWindow(leg.end_address, orders[i + 1]);
              windows.push(endAddressWindow);
              addMarkerClickEvent({
                marker: endAddressMarker,
                window: endAddressWindow,
                map,
                dispatch,
              });
            }
          });
        });

        google.maps.event.addListener(map, 'click', () => {
          for (let i = 0; i < windows.length; i += 1) {
            windows[i].close();
            if (anyRoutesFocused()) {
              $scope.focusedRoutes = $scope.dispatches;
              unhideRoutes();
              markerService.unhideMarkers();
            }
          }
        });
      });
    }

    const isMapEnabled = () => !!$scope.mapEnabled;
    const isMapDisabled = () => !isMapEnabled();
    const plotDispatches = () => _.each($scope.dispatches, plot);
    const plotUndispatchedOrders = () => _.each($scope.undispatched.orders, plotUndispatchedOrder);
    const centerMap = () =>
      _.set($scope, 'map.center', _.find($scope.regions, (region) => region.id === $scope.filters.region_id).center);
    const setupMap = _.flow(centerMap, plotDispatches, plotUndispatchedOrders);
    const toggleMapEnabled = () => _.set($scope, 'mapEnabled', !$scope.mapEnabled);

    // When focused routes drop down is edited. Make sure to update displayed routes on map
    $scope.focusedRoutesChange = () => {
      if (!$scope.mapEnabled) return;
      clearRoutes();
      markerService.hideMarkers($scope.focusedRoutes.map((d) => d.id));
      setupMap();
    };

    $scope.viewAllRoutes = () => {
      $scope.focusedRoutes = $scope.dispatches;
      resetMap();
      setupMap();
    };

    $scope.toggleMap = _.flow([_.cond([[isMapDisabled, setupMap]])], toggleMapEnabled);

    function chronologically(orders) {
      return orders.sort(chronologicalComparatorForOrders);
    }

    function groupings(orders) {
      const sorted = chronologically(orders);

      const groups = [];
      let group = null;
      _.each(sorted, (order) => {
        const duration = moment.duration(
          order.estimated_duration || DispatcherConstants.DEFAULT_ESTIMATED_DURATION,
          DispatcherConstants.ESTIMATED_DURATION_UNITS,
        );
        const offset = moment.duration(order.dispatch_offset, DispatcherConstants.DISPATCHER_OFFSET_UNITS);
        const scheduled = moment(moment.tz(order.scheduled, order.region.tz) + offset);
        const from = scheduled.hour() * 60 * 60 + scheduled.minute() * 60;
        const till = from + duration.asSeconds();
        if (!group || group.from >= till || group.till <= from) {
          group = { from, till, orders: [order] };
          groups.push(group);
        } else {
          group.orders.push(order);
          if (from < group.from) {
            group.from = from;
          }
          if (till > group.till) {
            group.till = till;
          }
        }
      });

      return groups;
    }

    $scope.layout = function () {
      resetMap();
      const placedDispatches = $scope.dispatches.filter((dispatch) => !dispatch._destroy);

      const orders = chronologically(
        _.filter(
          $scope.orders,
          (order) => !_.some(placedDispatches, (dispatch) => _.some(dispatch.orders, (other) => other.id === order.id)),
        ),
      );

      _.each(orders, (order) => {
        order.rescheduled =
          order.dispatch_id && !_.some($scope.dispatches, (dispatch) => dispatch.id === order.dispatch_id);
      });

      const parent_orders_and_restocks = orders.filter((order) => !order.parent_id || order.type == 'restock');
      $scope.undispatched = {
        orders: parent_orders_and_restocks,
        sectioned: sectioned(parent_orders_and_restocks, 'movers').sort((a, b) => b.section - a.section),
      };

      if ($scope.mapEnabled) {
        $scope.map.center = _.find($scope.regions, (region) => region.id === $scope.filters.region_id).center;
        plotUndispatchedOrders();
      }

      _.each($scope.dispatches, (dispatch, index) => {
        if ($scope.mapEnabled) plot(dispatch, index);
        dispatch.groupings = groupings(dispatch.orders.filter((order) => !order.parent_id || order.type == 'restock'));
      });
    };

    function attributes(publish) {
      const { dispatches } = $scope;

      return {
        publish,
        date: $scope.filters.date,
        region_id: $scope.filters.region_id,
        drivers_required: $scope.driversRequired(),
        non_drivers_required: $scope.nonDriversRequired(),
        batch: _.map(dispatches, (dispatch) => ({
          _destroy: dispatch._destroy,
          region_id: $scope.filters.region_id,
          lock_version: dispatch.lock_version,
          id: dispatch.id,
          arrival: dispatch.arrival,
          break_at: dispatch.break_at,
          mover_notes: dispatch.mover_notes,
          warehouse_notes: dispatch.warehouse_notes,
          addons: dispatch.addons,
          load_out: dispatch.load_out,
          depot_id: dispatch.depot ? dispatch.depot.id : null,
          warehouse_id: dispatch.warehouse ? dispatch.warehouse.id : null,
          orders_attributes: _.map(dispatch.orders, ({ id, dispatch_offset: dispatchOffset }) => ({
            id,
            dispatch_offset: dispatchOffset,
          })),
          assignments_attributes: _.map(dispatch.assignments, (assignment) => ({
            _destroy: assignment._destroy,
            id: assignment.id,
            role: assignment.role,
            user_id: assignment.user.id,
          })),
          vehicle_preferences_attributes: _.map(dispatch.vehicle_preferences, (preference) => ({
            id: preference.id,
            vehicle_type_id: preference.vehicle_type_id,
            quantity: preference.quantity,
            _destroy: preference._destroy,
          })),
          vehicle_recipe_attributes: dispatch.vehicle_recipe
            ? {
                id: dispatch.vehicle_recipe.id,
                min_quantity: dispatch.vehicle_recipe.min_quantity,
                max_quantity: dispatch.vehicle_recipe.max_quantity,
                minimum_cuft: dispatch.vehicle_recipe.minimum_cuft,
                allowed_vehicle_type_ids: dispatch.vehicle_recipe.allowed_vehicle_type_ids,
                notes: dispatch.vehicle_recipe.notes,
              }
            : null,
        })),
      };
    }

    $scope.published = () => _.some($scope.dispatches, (dispatch) => !!dispatch.published_at);

    $scope.cancel = function () {
      if ($scope.saving) {
        return;
      }
      $scope.mode = MODES.VIEWING;
      $scope.filter();
    };

    $scope.save = function (publish) {
      if ($scope.saving) {
        return;
      }
      $scope.saving = attributes(publish);
      $http({ method: 'PATCH', url: '/bulk/dispatch.json', data: $scope.saving }).then(
        () => {
          delete $scope.saving;
          $scope.cancel();
        },
        (error) => {
          delete $scope.saving;
          ErrorService.handle(error);
        },
      );
    };

    $scope.manage = function () {
      $scope.mode = MODES.MODIFYING;
    };

    $scope.filtering = function () {
      return $scope.filters.region_id && $scope.filters.date;
    };

    $scope.driverShiftCount = function () {
      return $scope.fieldShiftAssignments.filter((assignment) => assignment.qualified_driver).length;
    };

    $scope.nonDriverShiftCount = function () {
      return $scope.fieldShiftAssignments.filter((assignment) => !assignment.qualified_driver).length;
    };

    $scope.driversRequired = function () {
      return $scope.dispatches
        .filter((dispatch) => !dispatch._destroy)
        .reduce(function (acc, dispatch) {
          const totalRequiredMoverCount = Math.max(maxMoverCount(dispatch), 1);
          return acc + (totalRequiredMoverCount >= 4 ? 2 : 1);
        }, 0);
    };

    $scope.nonDriversRequired = function () {
      return $scope.dispatches
        .filter((dispatch) => !dispatch._destroy)
        .reduce(function (acc, dispatch) {
          const totalRequiredMoverCount = Math.max(maxMoverCount(dispatch), 1);
          return acc + (totalRequiredMoverCount >= 4 ? totalRequiredMoverCount - 2 : totalRequiredMoverCount - 1);
        }, 0);
    };

    function maxMoverCount(dispatch) {
      if (dispatch && dispatch.orders && dispatch.orders.length > 0) {
        return Math.max(...dispatch.orders.map((order) => order.movers || 1));
      } else {
        return 1;
      }
    }

    $scope.filter = function () {
      const filters = FiltersService.cleanup($scope.filters);
      $state.go('dispatcher', filters, { location: 'replace', notify: false });

      $scope.orders = null;
      $scope.fieldShiftAssignments = null;
      $scope.non_restock_child_orders = null;
      $scope.dispatches = null;
      $scope.undispatched = null;

      if ($scope.filtering()) {
        debounce
          .execute(
            $q.all([
              Dispatch.query(filters).$promise,
              Order.dispatching(filters).$promise,
              FieldShiftAssignment.dispatching(filters).$promise,
              $scope.users.$promise,
              $scope.regions.$promise,
              $scope.warehouses.$promise,
              $scope.depots.$promise,
            ]),
          )
          .then((results) => {
            $scope.dispatches = results[0].sort((a, b) => a.id - b.id).map((d) => ({ ...d, mapID: d.id }));
            $scope.focusedRoutes = $scope.dispatches;
            $scope.orders = results[1];
            $scope.fieldShiftAssignments = results[2];
            $scope.non_restock_child_orders = results[1].filter((order) => order.parent_id && order.type != 'restock');
            $scope.date = new Date(filters.date);
            $scope.region = _.find($scope.regions, (region) => region.id === Number($scope.filters.region_id));

            $scope.layout();
          });
      }
    };

    function clearStyleForDefaultDropZone() {
      $scope.styleForDefaultDropZone = null;
    }

    function resetStyleForDefaultDropZone(order, display) {
      const position = DispatcherPositionService.positionForOrder(order, { padded: true });
      $scope.styleForDefaultDropZone = {
        top: percentage(position.top),
        bottom: percentage(position.bottom),
        height: percentage(position.bottom - position.top),
        display,
      };
    }

    function clearStyleForHighlightDropZone() {
      $scope.styleForHighlightDropZone = null;
    }

    function resetStyleForHighlightDropZone(order) {
      const position = DispatcherPositionService.positionForOrder(order, { offset: draggingDispatchOffset });
      $scope.styleForHighlightDropZone = {
        top: percentage(position.top),
        bottom: percentage(position.bottom),
        height: percentage(position.bottom - position.top),
        display: 'block',
      };
    }

    $scope.dragmove = function (order, event) {
      if (!dragging) {
        return;
      }
      const mainBoundingClientRect = _.head(angular.element('.dispatcher-main')).getBoundingClientRect();
      const dropzoneBoundingClientRect = _.head(angular.element('.dispatcher-dropzone')).getBoundingClientRect();

      const { height } = mainBoundingClientRect;
      const dropzoneCenterY = (dropzoneBoundingClientRect.top + dropzoneBoundingClientRect.bottom) * 0.5;
      const units = height / (DispatcherConstants.DEFAULT_TIMES_TILL - DispatcherConstants.DEFAULT_TIMES_FROM);
      const delta = ((event.clientY - dropzoneCenterY) / units) * 3600;

      draggingDispatchOffset = Math.max(
        0,
        Math.round(delta / DispatcherConstants.DISPATCH_OFFSET_INTERVAL) * DispatcherConstants.DISPATCH_OFFSET_INTERVAL,
      );

      const displayWindowInSeconds = dragging.sla_window_size * DispatcherConstants.DEFAULT_WINDOW_SLOT_DURATION;
      if (draggingDispatchOffset > displayWindowInSeconds) {
        draggingDispatchOffset = displayWindowInSeconds;
      }

      resetStyleForHighlightDropZone(order);
    };

    $scope.dragstart = function (order) {
      resetStyleForDefaultDropZone(order, 'block');
      clearStyleForHighlightDropZone();
      dragging = order;
    };

    $scope.dragend = function (order) {
      resetStyleForDefaultDropZone(order, 'none');
      clearStyleForHighlightDropZone();
      dragging = null;
    };

    const isSelectionConflictingWithDragging = (selection, dragging) => {
      if (!selection) {
        return false;
      }
      if (isNotWarehouseOrder(dragging) && selection.orders.some(isWarehouseOrder)) {
        return true;
      }
      return (
        isWarehouseOrder(dragging) &&
        (selection.orders.some(isNotWarehouseOrder) ||
          selection.orders.some((order) => _.get(order, 'address.id') !== _.get(dragging, 'address.id')))
      );
    };

    $scope.dropped = function (selectionArg, travel_window_duration = DispatcherConstants.TRAVEL_WINDOW_DURATION) {
      if (isSelectionConflictingWithDragging(selectionArg, dragging)) {
        const mixedError = isWarehouseOrder(dragging)
          ? selectionArg.orders.some(isNotWarehouseOrder)
          : selectionArg.orders.some(isWarehouseOrder);
        const message = mixedError
          ? "Orders booked at the warehouse can't be dispatched with orders in the field."
          : "Orders booked at different warehouses can't be dispatched together.";
        return ErrorService.handle({ message });
      }
      const selection = selectionArg;

      clearStyleForDefaultDropZone();
      clearStyleForHighlightDropZone();

      const attached_non_restock_children = $scope.non_restock_child_orders.filter(
        (order) => order.parent_id == dragging.id,
      );

      if (!dragging.estimated_duration && dragging.type != 'restock' && dragging.type != 'disposal') {
        return ErrorService.handle({ message: MISSING_ESTIMATED_DURATION_MESSAGE });
      }

      dragging.dispatch_offset = draggingDispatchOffset || 0;

      _.each(attached_non_restock_children, (child) => {
        child.dispatch_offset = dragging.dispatch_offset;
      });

      _.each($scope.dispatches, (dispatch) => {
        dispatch.orders = _.filter(
          dispatch.orders,
          (order) => order !== dragging && (order.parent_id !== dragging.id || order.type == 'restock'),
        );
      });

      if (selection) {
        selection.orders.push(dragging);

        _.each(attached_non_restock_children, (child) => {
          selection.orders.push(child);
        });
      }
      dragging = null;

      _.each($scope.dispatches, (dispatch) => {
        if (dispatch.orders.length > 0) {
          const arrival = _.min(_.map(dispatch.orders, (order) => new Date(order.scheduled)));
          if (!dispatch.arrival) {
            dispatch.arrival = new Date(
              moment(arrival).subtract(travel_window_duration, DispatcherConstants.TRAVEL_WINDOW_UNITS),
            );

            dispatch.break_at = new Date(
              moment(dispatch.arrival).add(DispatcherConstants.BREAK_OFFSET, DispatcherConstants.BREAK_OFFSET_UNITS),
            );
          }
        } else {
          delete dispatch.arrival;
          delete dispatch.break_at;
        }
      });

      $scope.layout();
      return null;
    };

    const getWarehouse = (dragging) => {
      const targetWarehouseId =
        _.get(dragging, 'address.addressable_type') !== 'Warehouse'
          ? _.get($scope, 'region.default_warehouse_id')
          : _.get(dragging, 'address.addressable_id');
      return _.find($scope.warehouses, (warehouse) => warehouse.id === targetWarehouseId);
    };

    $scope.add = function (shouldCallLayout = true) {
      let warehouse = getWarehouse();
      if (dragging) {
        if (!dragging.estimated_duration) {
          return null;
        }
        warehouse = getWarehouse(dragging);
      }
      const dispatch = new Dispatch({
        orders: [],
        assignments: [],
        vehicles: [],
        region: $scope.region,
        warehouse,
        depot: _.find($scope.depots, (depot) => depot.id === $scope.region.default_depot_id),
        mapID: UUID(),
      });
      $scope.dispatches.push(dispatch);
      if (shouldCallLayout) $scope.layout();
      return dispatch;
    };

    $scope.remove = function (dispatch) {
      if (dispatch.id) {
        dispatch._destroy = true;
      } else {
        _.remove($scope.dispatches, dispatch);
      }
    };

    $scope.arrivalStartLineStyle = function (dispatch) {
      return {
        top: percentage(DispatcherPositionService.positionForTime(dispatch.arrival, dispatch.region.tz)),
      };
    };

    $scope.breakAtStartLineStyle = function (dispatch) {
      const breakEnd = new Date(
        moment(dispatch.break_at).add(DispatcherConstants.BREAK_LENGTH, DispatcherConstants.BREAK_LENGTH_UNIT),
      );
      const top = DispatcherPositionService.positionForTime(dispatch.break_at, dispatch.region.tz);
      const bottom = DispatcherPositionService.positionForTime(breakEnd, dispatch.region.tz);
      const height = bottom - top;

      return {
        top: percentage(top),
        height: percentage(height),
        bottom: percentage(bottom),
      };
    };

    $scope.style = function (order, index, grouping) {
      const position = DispatcherPositionService.positionForOrder(order, { offset: order.dispatch_offset });

      const style = {
        top: percentage(position.top),
        height: percentage(position.bottom - position.top),
        bottom: percentage(position.bottom),
      };

      if (index && grouping) {
        style.left = index && grouping ? percentage(index / grouping.orders.length) : 0.0;
      }

      return style;
    };

    $scope.times = {
      from: DispatcherConstants.DEFAULT_TIMES_FROM,
      till: DispatcherConstants.DEFAULT_TIMES_TILL,
    };

    $scope.dispatchHasWarehouseOrder = (dispatch) => dispatch.orders.some(isWarehouseOrder);

    $scope.legend = [];
    for (let index = $scope.times.from; index <= $scope.times.till; index += 1) {
      $scope.legend.push({
        name: index % 12 === 0 ? 12 : index % 12,
        position: percentage((index - $scope.times.from) / ($scope.times.till - $scope.times.from)),
      });
      $scope.legend.push(index);
    }

    $scope.isIncompleteVehicleRequest = (dispatch) =>
      !dispatch.vehicle_recipe ||
      !dispatch.vehicle_preferences ||
      dispatch.vehicle_preferences.length === 0 ||
      dispatch.vehicle_preferences.every((pref) => !pref.quantity || pref.quantity <= 0) ||
      !dispatch.vehicle_recipe.max_quantity ||
      !dispatch.vehicle_recipe.min_quantity ||
      !dispatch.vehicle_recipe.minimum_cuft ||
      !dispatch.vehicle_recipe.allowed_vehicle_type_ids ||
      dispatch.vehicle_recipe.allowed_vehicle_type_ids.length === 0;

    $scope.numPreferredVehicles = (dispatch) =>
      dispatch.vehicle_preferences.reduce((accumulator, pref) => {
        if (pref.quantity > 0) {
          return accumulator + pref.quantity;
        }
        return accumulator;
      }, 0);

    $scope.seatsAvailable = (dispatch) =>
      dispatch.vehicle_preferences.reduce((accumulator, pref) => {
        const defaultType = $scope.vehicleTypes.find((type) => type.id === pref.vehicle_type_id);
        if (!pref._destroy && pref.quantity && pref.quantity > 0 && defaultType) {
          return accumulator + pref.quantity * defaultType.num_seats;
        }
        return accumulator;
      }, 0);

    $scope.cuftUsed = (dispatch) =>
      dispatch.vehicle_preferences.reduce((accumulator, pref) => {
        const defaultType = $scope.vehicleTypes.find((type) => type.id === pref.vehicle_type_id);
        if (!pref._destroy && pref.quantity && pref.quantity > 0 && defaultType) {
          return accumulator + pref.quantity * defaultType.cuft;
        }
        return accumulator;
      }, 0);

    $scope.mismatchedVehiclePreferenceRecipe = (dispatch) => {
      if (!dispatch.vehicle_preferences) {
        return false;
      }

      const mismatchedPreferenceVehicleTypes = dispatch.vehicle_preferences.some(
        (pref) =>
          !pref._destroy &&
          pref.quantity &&
          pref.quantity > 0 &&
          !dispatch.vehicle_recipe.allowed_vehicle_type_ids.includes(pref.vehicle_type_id),
      );

      return (
        $scope.cuftUsed(dispatch) < dispatch.vehicle_recipe.minimum_cuft ||
        $scope.numPreferredVehicles(dispatch) > dispatch.vehicle_recipe.max_quantity ||
        $scope.numPreferredVehicles(dispatch) < dispatch.vehicle_recipe.min_quantity ||
        mismatchedPreferenceVehicleTypes
      );
    };

    const inefficientStaffing = ({ orders, assignments }) => {
      const { length } = assignments.filter(({ role, _destroy }) => !_destroy && role !== 'trainee');
      const overStaffableOrders = orders.filter(({ service_type }) => service_type === 'curbside_pickup');
      return overStaffableOrders.some(({ movers }) => movers < length);
    };

    const overstaffed = ({ orders, assignments }) => {
      const { length } = assignments.filter(({ role, _destroy }) => !_destroy && role !== 'trainee');
      const maxMovers = Math.max(...orders.map((order) => order.movers));
      return length > maxMovers;
    };

    const wrong_driver_count = ({ assignments, vehicle_recipe, vehicles }) => {
      const driving_assignments_count = assignments.filter(
        ({ role, _destroy }) => !_destroy && (role === 'lead' || role === 'driver'),
      ).length;

      if (vehicles.length > 0) {
        return vehicles.length !== driving_assignments_count;
      } else if (vehicle_recipe && vehicle_recipe.max_quantity) {
        return vehicle_recipe.max_quantity !== driving_assignments_count;
      } else {
        return driving_assignments_count !== 1;
      }
    };

    const doubleBookedWarnings = ({ id }) => {
      const multipleDispatchesWarningMessages = $scope.fieldShiftAssignments
        .filter(
          ({ multiple_dispatches_warning }) => multiple_dispatches_warning && multiple_dispatches_warning.includes(id),
        )
        .map((fieldShiftAssignments) => fieldShiftAssignments.multiple_dispatches_warning);
      return multipleDispatchesWarningMessages;
    };

    const moverCountErrors = (dispatch) => {
      const errors = [];

      if (inefficientStaffing(dispatch)) {
        errors.push({
          message: 'Too many movers assigned to a route with non Pickup & Packing orders.',
          status: Status.Fair,
        });
      }

      if (overstaffed(dispatch)) {
        errors.push({ message: 'Too many movers assigned to the route.', status: Status.Bad });
      }

      if (wrong_driver_count(dispatch)) {
        const message =
          dispatch.vehicles.length > 0
            ? 'The number of movers with a driving role (leads plus drivers) must be equal to the number of vehicles.'
            : 'The number of movers with a driving role (leads plus drivers) must be equal to the maximum number of vehicles required.';
        errors.push({ message: message, status: Status.Bad });
      }

      doubleBookedWarnings(dispatch).forEach((warning) => {
        errors.push({
          message: warning,
          status: Status.Fair,
        });
      });

      return errors;
    };

    const vehicleRequestErrors = (dispatch) => {
      const errors = [];
      if ($scope.isIncompleteVehicleRequest(dispatch)) {
        errors.push({
          message: 'Incomplete Vehicle Request. Please complete all fields in the Vehicle Request.',
          status: Status.Bad,
        });
      }

      const userMap = $scope.users.reduce((map, user) => {
        map[user.id] = user;
        return map;
      }, {});

      const driverCount = dispatch.assignments.reduce((accumulator, assignment) => {
        if (!assignment.user) {
          return accumulator;
        }

        const user = userMap[assignment.user.id];
        if (user && user.roles.includes('driver')) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);

      if (!dispatch.vehicle_preferences || driverCount < $scope.numPreferredVehicles(dispatch)) {
        errors.push({ message: 'Not enough drivers for the number of vehicles requested.', status: Status.Bad });
      }

      if (!dispatch.vehicle_preferences || $scope.seatsAvailable(dispatch) < dispatch.assignments.length) {
        errors.push({
          message: 'Not enough seats in the vehicles requested for the number of movers assigned.',
          status: Status.Bad,
        });
      }

      if (dispatch.load_out == null) {
        errors.push({ message: 'No material load out selected.', status: Status.Bad });
      }

      if (
        dispatch.vehicle_recipe &&
        dispatch.vehicle_preferences &&
        $scope.mismatchedVehiclePreferenceRecipe(dispatch)
      ) {
        errors.push({ message: 'Vehicle Preferences does not satisfy Vehicle Requirements.', status: Status.Bad });
      }

      return errors;
    };

    $scope.vehicleRequestIconStatus = (dispatch) => {
      const errors = vehicleRequestErrors(dispatch);
      return errors.reduce((currentWorstStatus, { status }) => Math.max(status, currentWorstStatus), Status.Good);
    };

    $scope.vehicleRequestErrors = (dispatch) => vehicleRequestErrors(dispatch).map(({ message }) => message);

    $scope.moverCountErrors = moverCountErrors;

    $scope.generateErrorTooltip = (dispatch) => {
      let tooltipTemplate = '<span>';
      const errors = $scope.vehicleRequestErrors(dispatch);
      errors.forEach((errorText) => {
        tooltipTemplate += `<br/>${errorText}<br/>`;
      });
      tooltipTemplate += '</span>';
      return tooltipTemplate;
    };

    $scope.filter();
  },
]);
